import React from 'react'
import Heading from '../searchbar/Heading'
import featured from './featured.css'
import FeaturedCard from './FeaturedCard'
const Featured = () => {
  return (
  <section className='featured background' id='Services'>
    <div className="featuedtitle container">
        <h1>Featured Property Types</h1>
        <p>Find All Types of Property</p>
        <FeaturedCard/>
    </div>
    
  </section>
  )
}

export default Featured
