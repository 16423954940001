import React from 'react'
import RecentCard from '../recent/RecentCard'
import {location} from '../featuredData'
import './explore.css'

const Explore = () => {
  return (
    <section className='location padding' id='Explore'>
    <div className="container">
        <div className="recenttitle">
          <h1>Explore By Location</h1>
          <p>Locate Your Property With Your Choice All Over The World </p>
        </div>
        <div className="content grid3 mtop">
          {location.map((val,index)=>(
            <div className="box explorebox" key={index}>
                <img src={val.cover} alt="" />
                <div className="overlay">
                  <h5>{val.name}</h5>
                </div>
            </div>
          ))}
        </div>
    </div>
  </section>
  )
}

export default Explore
