import React, { useState } from 'react'
import Heading from './Heading'
import searchbar from './searchbar.css'
import { useNavigate } from 'react-router-dom'
import Recent from '../recent/Recent'
import Featured from '../featured/Featured'
import { HashLink } from 'react-router-hash-link'

const Searchbar = () => {
    const navigate=useNavigate();
     
    const [loc,setLoc]=useState("");
    const [price,setPrice]=useState("");
    const [bedrooms,setBedrooms]=useState("");

    // const handleSearch = (e)=>{
    //     e.preventDefault();
    //     navigate('/#About');
    //     console.log(loc);
    //     console.log(price);
    //     console.log(bedrooms);
    //     <Recent searchLoc={loc} searchPrice={price} searchBedroom={bedrooms}/>
    // }

  return (
    <>
   <section className='searchbar'>
    <div className="container">
    <div className='heading'>
      <h1>Search Your Next Home</h1>
      <p>Find new and featured property located in your city</p>
    </div>
        {/* <Heading title="Search Your Next Home" subtitle="Find new and featured property located in your city"/> */}
        <form className='flex searchform'>
            <div className="searchitem">
                <span>Location</span>
                <input type="text" placeholder='city/street name' onChange={(e)=>setLoc(e.target.value)}/>
            </div>
            <div className="searchitem ">
                <span>Bedrooms</span>
                <input type="text" placeholder='No of Bedrooms' onChange={(e)=>setBedrooms(e.target.value)}/>
            </div>
            <div className="searchitem">
                <span>Price</span>
                <input type="text" placeholder='Enter Price' onChange={(e)=>setPrice(e.target.value)}/>
            </div>
            {/* <div className="searchitem box2">
                <span>Advance Filter</span>
                <input type="text" placeholder=''/>
            </div> */}
            <div className="sbtn">
                {/* <button onClick={handleSearch} >Search</button> */}
                <HashLink className='button' to={"#About"} >Search</HashLink>
            </div>
        </form>
    </div>
   </section>
            <Featured/>
            <Recent searchLoc={loc} searchPrice={price} searchBedroom={bedrooms}/>
   </>
  )
}

export default Searchbar
