import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import RecentCard from '../home/recent/RecentCard'
import Searchitem from './searchitem/Searchitem'
import './searchlist.css'
import './searchitem/searchitem.css'
import { useAuthContext } from '../context/hook/useAuthContext'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListSearch from './ListSearch'
import { getAllApartments } from '../api-helper/apartment'

const Searchlist = () => {
  const {user,dispatch}=useAuthContext();
  const [allProperty,setAllProperty]=useState([{}]);

  const [loc,setLoc]=useState("");
  const [bedroom,setBedroom]=useState("");
  const [floor,setFloor]=useState("");
  const [price,setPrice]=useState("");
  const [featured,setFeatured]=useState("");
  const [typeOfproperty,setTypeOfproperty]=useState("");

  const [searchResult,setSearchResult]=useState(false);


  const query=new URLSearchParams(window.location.search);
  console.log(query);
  console.log(query.get('user'));
  console.log(query.get('price'));

 

  useEffect(()=>{

    getAllApartments().then(res=>{
      console.log("all property received");
      console.log(res.data);
      setAllProperty(res.data);
    }).catch(err=>{
      console.log("err in fetching all apartment ");
    })

     const getSearches = async ()=>{
      console.log(user.existingUser)
     
        // console.log("hiii")
            const res=await axios.get("https://cheap.apartments-sale.com/api/user/searchlist",
            {headers :  {
              "authorization": `Bearer ${user.token}`
          } })
            .catch(err=> console.log("Error occured "));
      
          if(res.status!==200 && res.status!==201 ){
          return console.log("No success");
          }
        
          const data= await res.data;
          console.log(data);
      
     
  
      
   }

   getSearches();

  

  },[user,dispatch])


 

  const handleclick=()=>{
    console.log(loc);
    console.log(bedroom);
    console.log(floor);
    console.log(price);
    console.log(featured);
    console.log(typeOfproperty);

    // console.log(searchResult);

    setSearchResult(true);

    // console.log(searchResult);
    
  }

  


  return (
    <div>
      <Header/>
      <div className="listcontainer">
        <div className="listwrapper">
            {/* <ListSearch/> */}
             <div className="listsearch">
                    <h1 className='lstitle'>Search</h1>

                    <div className='serachData sd'>

                            <div className="listitem item">
                                <label htmlFor="">Location</label>
                                <input type="text" placeholder='Location' onChange={(e)=>setLoc(e.target.value)}/>
                            </div>
                            {/* <div className="listitem">
                                <label htmlFor="">Bedrooms</label>
                                <input  placeholder='No of Bedrooms' onChange={(e)=>setBedroom(e.target.value)}/>
                            </div> */}
                            {/* <div className="listitem">
                                <label htmlFor="">Floors</label>
                                <input  placeholder='No of Floors' onChange={(e)=>setFloor(e.target.value)}/>
                            </div> */}
                            <div className="listitem">
                                <label htmlFor="">Price</label>
                                <input  placeholder='Price' onChange={(e)=>setPrice(e.target.value)}/>
                            </div>
                            <div className="listitem">
                                <label htmlFor="">Bedrooms</label>
                                <input  placeholder='No of Bedrooms' onChange={(e)=>setBedroom(e.target.value)}/>
                            </div>
                            {/* <div className="listitem">
                                <label htmlFor="">Featured Type</label>
                              
                                <select className='typeOptions' name="" id="" style={{width:"90%"}} onChange={(e)=>setFeatured(e.target.value)}>
                                    
                                    <option className='typeOptions' value="Family House">Family House</option>
                                    <option className='typeOptions' value="House & Villa">House & Villa</option>
                                    <option className='typeOptions' value="Apartment">Apartment</option>
                                    <option className='typeOptions' value="Office & Studio">Office & Studio</option>
                                    <option className='typeOptions' value="Villa & Condo">Villa & Condo</option>
                                    <option className='typeOptions' value="">All</option> 
                                </select>
                            </div> */}
                            {/* <div className="listitem">
                                <label htmlFor="">Type Of Property</label>
                              
                                <select className='typeOptions' name="" id="" style={{width:"90%"}} onChange={(e)=>setTypeOfproperty(e.target.value)}>
                                    <option className='typeOptions' value="Rent">Rent</option>
                                    <option className='typeOptions' value="Sale">Sale</option>
                                    <option className='typeOptions' value="">Both</option> 
                                </select>
                            </div> 
                             */}
                        

                    </div>

                    {/* <div className="listitem">
                        <div className="searchbtn">
                            <button className='searchbtn1' onClick={handleclick}>Search</button>
                            <button className='searchbtn2' onClick={()=>setSearchResult(false)}>All</button>
                        </div>
                    
                    </div> */}
            </div> 


            <div className="listresult">
                   
                <div className="searchItem">

                {allProperty.map((val,ind)=>(

                      ( 
                          (!query.get('user') || query.get('user').trim()==="" ? true : val.user===query.get('user')) &&
                          (!loc || loc.trim()===""?true:val.location.toLowerCase().includes(loc.toLowerCase())) &&
                                
                          

                          (!price || price.trim()===""?true:val.price.split(',').join('').toLowerCase().includes(price.toLowerCase())) &&


                          (!bedroom || bedroom.trim()===""?true:(val.bedrooms ?val.bedrooms:val.No_of_bedroom) .toLowerCase().includes(bedroom.toLowerCase())) 
                      
                      && 
                      
                            <div key={ind}>
                                <Searchitem property={val} myproperty={false} home={false}/>
                            </div>

                      )
                ))
                
                }

                        {/* { searchResult ?
                          
                            allProperty.map((val,ind)=>(

                         ( 
                          (!loc || loc.trim()===""?true:val.location.toLowerCase().includes(loc.toLowerCase()))
                           
                           && 
                            
                           <div key={ind}>
                                <Searchitem property={val} myproperty={false}/>
                            </div>

                            )
                            
                        ))

                        :

                            allProperty.map((val,ind)=>(
                                <div key={ind}>
                                    <Searchitem property={val} myproperty={false}/>
                                </div>
                                
                            ))
                        } */}

                </div>
                 {/* <Searchitem myproperty={false}/>       */}
            </div>

        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Searchlist
