import React from 'react'
import './footer.css'
import {footer} from '../featuredData'

const Footer = () => {
  return (
  <>
  
    <section className='footerContact' id='Contact'>
        <div className="container">
            <div className="send flex">
                <div className="text">
                    <h1>Do You Have Questions?</h1>
                    <p>We'll help you to grow your career and growth</p>
                </div>
                <button className='btn5'>Contact us today</button>
            </div>
        </div>
    </section>

    <footer>
        <div className="container">
            <div className="box">
                <div className="logo">
                    <img src="http://storage.googleapis.com/a3_visual/apartments/logo-apartment/apartment-logo-2.jpeg" alt="" />
                
                    <h2>Do You Need Help With Anything?</h2>
                    <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                
                    <div className="input">
                        <input type="text" placeholder='Email Address' />
                        <button className='btn6'>Subscribe</button>
                    </div>
                </div>
            </div>
            
            {/* <div className="footercon"> */}
                    {footer.map((val,index)=>(
                        <div className="box" key={index}>
                            <h3>{val.title}</h3>
                            <ul>
                                {val.text.map((item)=>(
                                    <li>{item.list}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
            {/* </div> */}

           

        </div>
    </footer>

    </>
  )
}

export default Footer
