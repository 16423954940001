import React, { useState } from 'react'
import header from './header.css'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import {useAuthContext} from '../context/hook/useAuthContext'
import { useNavigate } from 'react-router-dom'
import ListIcon from '@mui/icons-material/List';


const Header = (props) => {
    const navlinks=["Home","Services","About","Achievments","Explore","Contact"]
    const loginlist=["Home"]
    const [togglelist,setTogglelist]=useState(false)
    const navigate=useNavigate()

    const {user,dispatch}=useAuthContext();

    const handleLogout=()=>{
      localStorage.removeItem('user')
      dispatch({type:"LOGOUT"})
      navigate("/")
      
      
    }
    const handlenav=()=>{
      setTogglelist(false)
    }

  return (
    <header>
        <div className="container flex" >
            <div className="logo" >
                <img style={{maxWidth:"6rem",maxHeight:"3rem"}} src="http://storage.googleapis.com/a3_visual/apartments/logo-apartment/apartment-logo-2.jpeg" alt="" />
            </div>
            <div className="nav">

         
          
    
                <ul className={togglelist?"small":"flex" }>

              


                    { !user &&
                    
                     navlinks.map((nav,index)=>(
                      <li key={index}>
                        <HashLink  to={`${nav==="Home"?"#Home":`#${nav.split(" ").join("")}`}`} onClick={handlenav} >{nav}</HashLink>
                      </li>
                    )) }

                    {user && 
                    <>
                    
                    <li >
                      {/* <HashLink  to='#Home' onClick={handlenav}  >Home</HashLink> */}
                    
                      <HashLink  to='/searchlist' >Searchlist</HashLink>
                      
                    </li>
                    <li>
                    <HashLink  to='/addproperty' >Add Property</HashLink>
                    </li>
                    <li>
                    <HashLink  to='/myproperties' >My Properties</HashLink>
                    </li>
                    </>

                    }

                    {togglelist && 
                    <>
                     {!user &&
                      <li>
                      <HashLink to='/login'>Login</HashLink>
                     </li>}
                     { !user &&
                      <li>
                      <HashLink to='/register'>Register</HashLink>
                     </li>}
                     {user &&
                       <li>
                       <HashLink to='/' onClick={handleLogout}>Logout</HashLink>
                      </li>
                     }
                    </>
                    }

                

                </ul>
            </div>
            <div className="btns flex">
             
                { !user &&   <button className='btn' onClick={()=>window.location.href='/login'}>Login</button>  }
                { !user && <button className='btn' onClick={()=>window.location.href='/register'}>Register</button> }
                { user && <button className='logoutbtn' onClick={handleLogout}>Logout</button> }
            
            </div>
            <div className='toggle'>
              
                <button onClick={()=>setTogglelist(!togglelist)} className='togglebtn'>< ListIcon sx={{fontSize:"30px"}}/></button>
            </div>
        </div>
    </header>
  )
}

export default Header
