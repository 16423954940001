import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import './addproperty.css'
import { useParams } from 'react-router-dom'
import { ToastContainer,toast } from 'react-toastify'
import { getApartmentByID, updateApartmentByID } from '../../api-helper/apartment'
import { useAuthContext } from '../../context/hook/useAuthContext'

const EditProperty = (props) => {
  const {user,dispatch}=useAuthContext();
  const [editPropertyData,setEditPropertyData]=useState({location:"",imgurl:"",weburl:"",bedrooms:"",price:"",featuredType:"",propertyType:"",phone:"",email:""})

  const params=useParams();
  console.log("Params")
  console.log(params.id);

  useEffect(()=>{
    getApartmentByID(user.token,params.id).then(res=>{
      console.log("edit property");
      console.log(res.data);
      setEditPropertyData({location:res.data.location, imgurl:res.data.img_link, weburl:res.data.web_link, bedrooms:res.data.No_of_bedroom, price:res.data.price, featuredType:res.data.featured_type,propertyType:res.data.type_of_property,phone:res.data.phone,email:res.data.email})
      console.log(editPropertyData);
    }).catch(err=>{
      console.log("edit error");
      console.log(err);
    })
  },[user,dispatch]);
  

    const handleAddProperty=(e)=>{
      e.preventDefault();
      console.log("Update function");
      console.log(user.token);
        updateApartmentByID(JSON.parse(localStorage.getItem('user')).token,params.id,editPropertyData).then(res=>{
          console.log("updated success");
          console.log(res);
          toast.success("Updated Successfully");

        }).catch(err=>{
          console.log("Not Updated");
          console.log(err);
        })
    };

    const handleChange= async  (e)=>{
      setEditPropertyData((prevState)=>({
        ...editPropertyData,
        [e.target.name]: e.target.value, 
       }));
   
    };

    console.log(editPropertyData);

  return (
     <>
          <Header/>
          <div className='outerContainer'>
            <div className="innerContainer">
              <div className="imgContainer">
                    <img src="https://img.freepik.com/free-vector/house-rent-abstract-concept-illustration-booking-house-online-best-rental-property-real-estate-service-accommodation-marketplace-rental-listing-monthly-rent_335657-1123.jpg?w=2000" alt="" />
              </div>
              <div className="formContainer">
                    <div className="addpropertytitle">
                        <h1>Edit Your Property</h1>
                    </div>
                    <form onSubmit={handleAddProperty}  className='addpropertyForm'>
                            <div className="addpropertyItem">
                              <p className='inputP'>Location</p>
                              <input type="text" name="location" id="" placeholder=''  onChange={handleChange} value={editPropertyData.location} />
                            </div>
                            <div className="addpropertyItem">
                              <p className='inputP'>Image Url</p>
                              <input type="text" name="imgurl" id="" placeholder=''  onChange={handleChange} value={editPropertyData.imgurl}/>
                            </div>
                            <div className="addpropertyItem">
                              <p className='inputP'>Web Url</p>
                              <input type="text" name="weburl" id="" placeholder='' onChange={handleChange} value={editPropertyData.weburl}/>
                            </div>
                            <div className="addpropertyItem">
                             <p className='inputP'>Bedrooms</p>
                              <input type="text" name="bedrooms" id="" placeholder='' onChange={handleChange} value={editPropertyData.bedrooms}/>
                            </div>
                            <div className="addpropertyItem">
                            <p className='inputP'>Price</p>
                              <input type="text" name="price" id="" placeholder='' onChange={handleChange} value={editPropertyData.price}/>
                            </div>
      
                            <div className="addpropertyItem">
                              <p className='inputP'>Featured Type</p>
                              {/* <input type="text" name="featuredType" id="" placeholder='' onChange={handleChange} value={editPropertyData.featuredType}/>   */}
                              <select className='typeOptions' name="featuredType" id="" onChange={handleChange} value={editPropertyData.featuredType}>
                                <option className='typeOptions' value="Family House">Family House</option>
                                <option className='typeOptions' value="House & Villa">House & Villa</option>
                                <option className='typeOptions' value="Apartment">Apartment</option>
                                <option className='typeOptions' value="Office & Studio">Office & Studio</option>
                                <option className='typeOptions' value="Villa & Condo">Villa & Condo</option>
                             </select>
                            </div>
      
                            <div className="addpropertyItem">
                            <p className='inputP' style={{marginTop:"10px"}}>Type of Property</p>
                             <select className='typeOptions' name="propertyType" id="" onChange={handleChange} value={editPropertyData.propertyType} >
                              <option className='typeOptions' value="Rent">Rent</option>
                              <option className='typeOptions' value="Sale">Sale</option>
                             </select>
                            </div>

                        <div className="addpropertyItem" style={{marginTop:"20px"}}>
                           <p className='inputP'>Phone</p>
                           <input type="text" name="phone" id="" placeholder='' onChange={handleChange} value={editPropertyData.phone}/>
                       </div>

                      <div className="addpropertyItem">
                          <p className='inputP'>Email</p>
                          <input type="email" name="email" id="" placeholder='' onChange={handleChange} value={editPropertyData.email}/>
                      </div>

      
                            <div className="addpropertyItem">
                              <button className='addbtn' type='submit' style={{cursor:"pointer"}} >Update Property</button>
                            </div>
                            <ToastContainer/>
                    </form>
              </div>
            </div>
              
          </div>
        </>
        
       
  )
}

export default EditProperty
