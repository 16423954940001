import axios from "axios"
import {toast} from 'react-toastify'

export const addApartment = async (token,userid,propertyData)=>{
        const property={
            user:userid,
            location:propertyData.location,
            img_link:propertyData.imgurl,
            web_link:propertyData.weburl,
            No_of_bedroom:propertyData.bedrooms,
            price:propertyData.price,
            featured_type:propertyData.featuredType,
            type_of_property:propertyData.propertyType,
            phone:propertyData.phone,
            email:propertyData.email
        }
        const res=await axios.post("https://cheap.apartments-sale.com/api/apartment/addApartment",property,{
         headers:{
            "Authorization":`Bearer ${token}`
          }
        }
        
       )
           .catch(err=>{
            
             console.log("Property not added");
             toast.error("Something Went Wrong",{
                position: "top-center",
                theme: "colored",
                })
            
          });
    
           
        if(res.status!==200 && res.status!==201 ){
         return console.log("error occured property not added");
        }
       console.log("property added succesfully");
          console.log(res)
     
          const data= await res.data;
          return data;
        
       
        
     
}

export const getUserApartment = async (token,userId)=>{
  
  const res=await axios.get(`https://cheap.apartments-sale.com/api/apartment/getalluserApartment/${userId}`,{
    headers:{
      "Authorization":`Bearer ${token}`
    }
})
     .catch(err=>{
      
       console.log("user property did not got");
       toast.error("Something Went Wrong",{
          position: "top-center",
          theme: "colored",
          })
      
    });

     
  if(res.status!==200 && res.status!==201 ){
   return console.log("error occured user property not received");
  }
 console.log("user property got succesfully");
    console.log(res)

    const data= await res.data;
    return data;
  
 
  

}

export const getApartmentByID = async (token,propertyId)=>{
  
  const res=await axios.get(`https://cheap.apartments-sale.com/api/apartment/getappartment/${propertyId}`,{
    headers:{
      "Authorization":`Bearer ${token}`
    }
  })
  
     .catch(err=>{
      
       console.log("Editing property did not got");
       toast.error("Something Went Wrong getby Id",{
          position: "top-center",
          theme: "colored",
          })
      
    });

     
  if(res.status!==200 && res.status!==201 ){
   return console.log("error occured editable property not received");
  }
 console.log("editable property got succesfully");
    console.log(res)

    const data= await res.data;
    return data;
  
}

export const updateApartmentByID = async (token,propertyId,editedData)=>{
  const body={
    id:propertyId,
    location:editedData.location,
    img_link:editedData.imgurl,
    web_link:editedData.weburl,
    No_of_bedroom:editedData.bedrooms,
    price:editedData.price,
    featured_type:editedData.featuredType,
    type_of_property:editedData.propertyType,
    phone:editedData.phone,
    email:editedData.email
  }

  const res=await axios.put("https://cheap.apartments-sale.com/api/apartment/updateAppartment",body,
    {
      headers:{
      "Authorization":`Bearer ${token}`
    }  }
  )
     .catch(err=>{
      console.log(err)
       console.log("updated property did not got");
       toast.error("Something Went Wrong getby Id",{
          position: "top-center",
          theme: "colored",
          })
      
    });

     
  if(res.status!==200 && res.status!==201 ){
   return console.log("error occured updated property not received");
  }
 console.log("updated property got succesfully");
    console.log(res)

    const data= await res.data;
    return data;
  
}

export const getAllApartments = async ()=>{
  
  const res=await axios.get("https://cheap.apartments-sale.com/api/apartment/getallApartment")
     .catch(err=>{
      console.log(err);
       console.log("All property did not got");
       toast.error("Something Went Wrong",{
          position: "top-center",
          theme: "colored",
          })
      
    });

     
  if(res.status!==200 && res.status!==201 ){
   return console.log("error occured All property not received");
  }
 console.log("All property got succesfully");
    console.log(res)

    const data= await res.data;
    return data;
}

export const deleteApartmentByID = async (token,propertyId)=>{
  
  const res=await axios.delete(`https://cheap.apartments-sale.com/api/apartment/deleteApartment/${propertyId}`,{
   headers:{
      "Authorization":`Bearer ${token}`
    }
  })
     .catch(err=>{
      
       console.log("deleting property did not got");
       toast.error("Something Went Wrong getby Id",{
          position: "top-center",
          theme: "colored",
          })
      
    });

     
  if(res.status!==200 && res.status!==201 ){
   return console.log("error occured deleting property not received");
  }
 console.log("deleting property got succesfully");
    console.log(res)

    const data= await res.data;
    return data;
  
}