import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { googleLogin, loginUser, registerGoogleUser } from '../../api-helper/helper';
import '../registration/Reg.css'
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useAuthContext } from '../../context/hook/useAuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login';


const Login = (props) => {

  useEffect(()=>{
    localStorage.removeItem('newpass')
  },[])

  const navigate=useNavigate();
  const [loginData,setloginData]=useState({email:"",password:""});
  const {dispatch} = useAuthContext();


const login = useGoogleLogin({
  onSuccess: async tokenResponse => {
   
    console.log(tokenResponse)
   const userData = await googleLogin(tokenResponse);
   console.log("See below")
   console.log(userData)

   if(userData.email_verified){
       const resData = await registerGoogleUser(userData.name,userData.email);
       console.log(resData);
       localStorage.setItem('user',JSON.stringify(resData));
       dispatch({type:'LOGIN',payload:resData});
      
       navigate("/searchlist");
       
   }
   else {
    console.log("Email is not verified")
   }
    
  
  }
});


  const handleSubmit = (e)=>{

    e.preventDefault();
    console.log("hello");
    console.log(loginData);

    if(!loginData.email && loginData.email.trim()===""){
      console.log("Emial empty")
      toast.error("Email cant be empty",{
        position: "top-center",
        theme: "colored",
        })
    }
    else if(!loginData.password && loginData.password.trim()==="") {
      console.log("Password empty")
      toast.error("Password cant be empty",{
        position: "top-center",
        theme: "colored",
        })
  }
  else{
    loginUser(loginData).then(data=>{
      console.log("dataishere"+data)
      if(data){
        localStorage.setItem('user',JSON.stringify(data));
        console.log("success");
        console.log(data);
        dispatch({type:'LOGIN',payload:data});
        navigate("/searchlist");
      }
      else{
        console.log("Sorry No data got")
        toast.error("Something Went Wrong",{
          position: "top-center",
          theme: "colored",
          })
      }

    })
    .catch(err=>console.log(err));
  }

};

const handleChange= async  (e)=>{
  setloginData((prevState)=>({
    ...prevState,
    [e.target.name]: e.target.value,
    
   }));

  
};


const responseFacebook =  (response) => {
  console.log(response.name+response.email);
      registerGoogleUser(response.name,response.email).then(data=>{
        localStorage.setItem('user',JSON.stringify(data));
        console.log("success");
        console.log(data);
        dispatch({type:'LOGIN',payload:data});
      })
      .then(()=>{
        navigate("/searchlist");
        console.log("done");
      })
      .catch(err=>console.log(err));
      
}
  



  return (
    <div className='formcontainer'>
        <form onSubmit={handleSubmit} className='Authform'>
            <div className='forminputs'>

            <div className="closeicon">
                        <span onClick={()=>window.location.href='/'}><Close sx={{cursor:"pointer"}}/></span>
                  </div>
                
                <h1>Login</h1>
                
            <div className="emailinp">
                <label for="email"><b>Email</b></label>
            </div>
            <input className='inputText' type="text" placeholder="Enter Email" onChange={handleChange} value={loginData.email} name="email" id="email" required/>

            <div className="emailinp">
                <label for="psw"><b>Password</b></label>
            </div>
            <input style={{marginBottom:"0px"}} className='inputText' type="password" placeholder="Enter Password" onChange={handleChange} value={loginData.password}  name="password" id="password" required/>
             <div className="emailinp" style={{textAlign:"right"}}>
                 <a style={{margin:"0px",marginLeft:"auto",color:" #007bff",fontSize:"14px"}} href="/forgotPassword">Forgot Password?</a>
             </div>
            <div className="signin">
                <button type="submit" className='btn'>Login</button>
                
                <p style={{marginBottom:"10px"}}>Already have an account? <a href="/register">Sign up</a>.</p>
                
            </div>
            <div class="Orcontent signin">
                  <p class="or">or</p>
            </div>
            <div className="signin">
                <button type="submit"  onClick={login} className='Googlebtn'>  Continue with Google</button>
                
                <button  className="Googlebtn" style={{padding:"0px",border:"none"}}>
                <FacebookLogin 
               
                  appId="3071259203176086"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton="Continue With Facebook"
                  buttonStyle={{textTransform:"none",
                  backgroundColor: "#fff",
                  width: "100%",
                  color: "#000000",
                  outline: "none",
                  border:" 1px solid rgb(33, 33, 33)",
                  padding: "10px",
                  fontSize:"16px",
                  fontWeight: "400",
                  borderRadius: "10px",
                  marginTop: "0px"}}
              />
                </button>
                
            
            </div>

            </div>

            < ToastContainer/>

    
        </form>
    </div>
  )
}

export default Login
