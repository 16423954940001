import axios from "axios"


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const base_url="https://apartments-sale.azurewebsites.net https://cheap.apartments-sale.com"
 const base_url="http://localhost:8000"


export const registerUser = async (registerData)=>{
    const res=await axios.post("https://cheap.apartments-sale.com/api/user/signup",{
       name:registerData.name,
       email:registerData.email,
       password:registerData.password})
       .catch(err=>{
        if(err.response.status===409){
         console.log("email exits in db")
         toast.error("Email Already Exist",{
            position: "top-center",
            theme: "colored",
            })
        }
        else{
          console.log(err)
         return console.log("Error Occured No registration")
        }
      });

       
    if(res.status!==200 && res.status!==201 ){
     return console.log("Some Error Occured No registration");
    }
    toast.error("Verification Link sent to Email",{
      position: "top-center",
      theme: "colored",
      })
      console.log(res)
      console.log("Result")
    if(res){
      const data= await res.data;
      return data;
    }
   
    
 }

 export const googleLogin = async (tokenResponse)=>{

   try{
      const data= await axios.get("https://www.googleapis.com/oauth2/v3/userinfo",{
        headers:{
          "Authorization":`Bearer ${tokenResponse.access_token}`
        }
      })
      console.log("below data")
      console.log(data.data.name)
      console.log(data.data)
      return data.data;
      
  }
  catch(err){
    console.log(err); 
    toast.error("Something went Wrong",{
      position: "top-center",
      theme: "colored",
      })

  }

  


   // const res=await axios.post("http://localhost:8000/user/signup",{
   //    name:registerData.name,
   //    email:registerData.email,
   //    password:"registerDatapassword"})
   //    .catch(err=> console.log("Error occured Not registerd"));

   // if(res.status!==200 && res.status!==201 ){
   //  return console.log("Some Error Occured No registration");
   // }
   // const data= await res.data;
   // return data;
   
}

export const registerGoogleUser = async (username,useremail)=>{
  console.log("Googgle login here")
   const res=await axios.post("https://cheap.apartments-sale.com/api/user/googleLogin",{
      name:username,
      email:useremail,
      password:"googleLoginUserAtApartmentSite",
      })
      .catch(err=>{
         console.log("Error occured Not registerd")
         toast.error("Something went Wrong",{
           position: "top-center",
           theme: "colored",
           })
      });

   if(res.status!==200 && res.status!==201 ){
    return console.log("Some Error Occured No registration");
   }
   const data= await res.data;
   return data;
   
}


 export const loginUser = async (loginData)=>{
    const res=await axios.post("https://cheap.apartments-sale.com/api/user/login",{
       email:loginData.email,
       password:loginData.password})
       .catch(err=> {
         if(err.response.status===404){
            console.log("Email doesn't Exist")
            toast.error("User Does Not Exist",{
               position: "top-center",
               theme: "colored",
               })
           }
           else if(err.response.status===400){
            toast.error("Password does not matched",{
              position: "top-center",
              theme: "colored",
              })
           }
           else if(err.response.status===409){
            toast.error("Email Not Verified...Verify Email or Please Register again",{
              position: "top-center",
              theme: "colored",
              })
           }
           else{
            console.log("Error Occured No Login")
           }
         
      });
 
    if(res.status!==200 && res.status!==201 ){
      toast.error("Something went Wrong",{
         position: "top-center",
         theme: "colored",
         })
     return console.log("Login Not done");
    
    }
  console.log("insecurre")
    if(res){
      const data= await res.data;
      return data;
    }
    
 }

 export const sendForgotlink = async (email)=>{
  const res=await axios.post("https://cheap.apartments-sale.com/api/user/forgotPass",{
     email:email,
     })
     .catch(err=>{
      if(err.response.status===409){
       console.log("email exits in db")
       toast.error("Email Already Exist",{
          position: "top-center",
          theme: "colored",
          })
      }
      else{
       return console.log("Error Occured No registration")
      }
    });

     
  if(res.status!==200 && res.status!==201 ){
   return console.log("Some Error Occured No registration");
  }
  
  if(res){
    const data= await res.data;
    return data;
  }
 
  
}
export const setPass = async (password,token)=>{
  console.log("pass help")
  console.log(token)
  const res=await axios.post(`https://cheap.apartments-sale.com/api/user/setNewPass/${token}`,{
    password:password,
    
    })
    .catch(err=>{
     if(err.response.status===409){
      console.log("email exits in db  setPass")
      toast.error("Email Already Exist setPass",{
         position: "top-center",
         theme: "colored",
         })
     }
     else{
      return console.log("Error Occured No registration setPass")
     }
   });

    
 if(res.status!==200 && res.status!==201 ){
  return console.log("Some Error Occured No registration setPass");
 }
 
 if(res){
   const data= await res.data;
   return data;
 }


}

//  export const getSearches = async ()=>{

//     const {user} = useAuthContext();

//     const res=await axios.get("http://localhost:8000/user/searchlist",
//       {headers :  {
//         "Authorization": `Bearer ${user.token}`
//      } })
//        .catch(err=> console.log("Error occured "));
 
//     if(res.status!==200 && res.status!==201 ){
//      return console.log("success");
//     }
  
//     const data= await res.data;
//     return data;
//  }