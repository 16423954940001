export const featured = [
    {
      cover: "https://funny-daffodil-350bc9.netlify.app/images/hero/h1.png",
      name: "Family House",
      total: "122 Property",
    },
    {
      cover: "https://funny-daffodil-350bc9.netlify.app/images/hero/h2.png",
      name: "House & Villa",
      total: "155 Property",
    },
    {
      cover: "https://funny-daffodil-350bc9.netlify.app/images/hero/h3.png",
      name: "Apartment",
      total: "300 Property",
    },
    {
      cover: "https://funny-daffodil-350bc9.netlify.app/images/hero/h4.png",
      name: "Office & Studio",
      total: "80 Property",
    },
    {
      cover: "https://funny-daffodil-350bc9.netlify.app/images/hero/h6.png",
      name: "Villa & Condo",
      total: "80 Property",
    },
  ]

  export const list = [
    {
      id: 1,
      cover: "https://funny-daffodil-350bc9.netlify.app/images/list/p-1.png",
      name: "Red Carpet Real Estate",
      location: "210 Zirak Road, Canada",
      category: "For Rent",
      price: "$3,700",
      type: "Apartment",
    },
    {
      id: 2,
      cover: "https://funny-daffodil-350bc9.netlify.app/images/list/p-2.png",
      name: "Fairmount Properties",
      location: "5698 Zirak Road, NewYork",
      category: "For Sale",
      price: "$9,750",
      type: "Condos",
    },
    {
      id: 3,
      cover: "https://funny-daffodil-350bc9.netlify.app/images/list/p-7.png",
      name: "The Real Estate Corner",
      location: "5624 Mooker Market, USA",
      category: "For Rent",
      price: "$5,860",
      type: "Offices",
    },
    {
      id: 4,
      cover: "https://funny-daffodil-350bc9.netlify.app/images/list/p-4.png",
      name: "Herringbone Realty",
      location: "5621 Liverpool, London",
      category: "For Sale",
      price: "$7,540",
      type: "Homes & Villas",
    },
    {
      id: 5,
      cover: "https://funny-daffodil-350bc9.netlify.app/images/list/p-5.png",
      name: "Brick Lane Realty",
      location: "210 Montreal Road, Canada",
      category: "For Rent",
      price: "$4,850",
      type: "Commercial",
    },
    {
      id: 6,
      cover: "https://funny-daffodil-350bc9.netlify.app/images/list/p-6.png",
      name: "Banyon Tree Realty",
      location: "210 Zirak Road, Canada",
      category: "For Sale",
      price: "$2,742",
      type: "Apartment",
    },
  ]
  export const work = [
    {
      num: "1500+",
      name: "Benguluru,India",
    },
    {
      num: "1200+",
      name: "Mumbai,India",
    },
    {
     
      num: "700+",
      name: "Pune,India",
    },
    {
      num: "500+",
      name: "Kolkata,India",
    },
  ]

  export const location = [
    {
      id: 1,
      name: "New Orleans, Louisiana",
      Villas: "12 Villas",
      Apartments: "10 Apartments",
      Offices: "07 Offices",
      cover: "https://funny-daffodil-350bc9.netlify.app/images/location/city-1.png",
    },
    {
      id: 2,
      name: "Jerrsy, United State",
      Villas: "12 Villas",
      Apartments: "10 Apartments",
      Offices: "07 Offices",
      cover: "https://funny-daffodil-350bc9.netlify.app/images/location/city-2.png",
    },
    {
      id: 3,
      name: "Liverpool, London",
      Villas: "12 Villas",
      Apartments: " 10 Apartments",
      Offices: "07 Offices",
      cover: "https://funny-daffodil-350bc9.netlify.app/images/location/city-3.png",
    },
    {
      id: 4,
      name: "NewYork, United States",
      Villas: "12 Villas",
      Apartments: " 10 Apartments",
      Offices: "07 Offices",
      cover: "https://funny-daffodil-350bc9.netlify.app/images/location/city-4.png",
    },
    {
      id: 5,
      name: "Montreal, Canada",
      Villas: "12 Villas",
      Apartments: " 10 Apartments",
      Offices: "07 Offices",
      cover: "https://funny-daffodil-350bc9.netlify.app/images/location/city-5.png",
    },
    {
      id: 6,
      name: "California, USA",
      Villas: "12 Villas",
      Apartments: " 10 Apartments",
      Offices: "07 Offices",
      cover: "https://funny-daffodil-350bc9.netlify.app/images/location/city-6.png",
    },
  ]

  export const footer = [
    {
      title: "LAYOUTS",
      text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }, { list: "Single Blog" }],
    },
    {
      title: "ALL SECTIONS",
      text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
    },
    {
      title: "COMPANY",
      text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
    },
  ]