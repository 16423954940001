import React from 'react'
import { featured } from '../featuredData'
// import { featured } from './featured.css'

const FeaturedCard = () => {
  return (
    <>
        <div className="content grids mtop">
     
          {featured.map((items,index)=>(
                <div className="box featuredbox" key={index}>
                    <img src={items.cover} alt="" />
                    <h4>{items.name}</h4>
                    <label>{items.total}</label>
                </div>
            ))}
          
           
        </div>
    </>
  )
}

export default FeaturedCard
