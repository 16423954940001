import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import './Reg.css'
import { useAuthContext } from '../../context/hook/useAuthContext'
import { registerUser } from '../../api-helper/helper'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";
import '../emailVerification/email.css'


const Registration = (props) => {

  const [registerData,setregisterData]=useState({name:"",email:"",password:"",confirmPass:""});
  const {dispatch} = useAuthContext();
  const [goBack,setgoBack]=useState(false)
   
  const navigate=useNavigate()

  const handleSubmit = (e)=>{
    e.preventDefault();
    console.log("hello");
    console.log(registerData);

    if(!registerData.name && registerData.name.trim()===""){
      console.log("valid name");
      toast.error("Enter valid Name",{
        position: "top-center",
        theme: "colored",
        })
      
    }
    else if(!registerData.email && registerData.email.trim()===""){ 
      console.log("null email");
      toast.error("Email cant be empty",{
        position: "top-center",
        theme: "colored",
        })
    }
    else if(!validator.isEmail(registerData.email)){
      console.log("enter valid email ");
      toast.error("Enter valid Email",{
        position: "top-center",
        theme: "colored",
        })
    }
    else if(!registerData.password && registerData.password.trim()===""){
      console.log("null pass");
      toast.error("Password cant be empty",{
        position: "top-center",
        theme: "colored",
        })
    }
    // else if (!validator.isStrongPassword(registerData.password, {
    //   minLength: 6
    // })) {
    //   console.log("weak password")
    //   toast.error("Password length should at least 6",{
    //     position: "top-center",
    //     theme: "colored",
    //     })
    // }
    else if(registerData.password!==registerData.confirmPass){
      console.log("pass does not match");
      toast.error("Password doesnt Match",{
        position: "top-center",
        theme: "colored",
        })
     
    }  
    else{
      console.log("fields okk");
      registerUser(registerData).then(data=>{
        console.log("err data");
        // console.log(data)
        //local storage 
        if(data){
          props.setVerificationpage(true)
          
          // localStorage.setItem('tknexpired',true);
          setgoBack(true)
          console.log("done");
        }
        else{
          console.log("Sorry No data got")
          toast.error("Email Already exits",{
            position: "top-center",
            theme: "colored",
            })
        }
        
      }).catch(err=>console.log(err));
      
    }
 
};
  
  
  const handleChange= async  (e)=>{
    setregisterData((prevState)=>({
      ...registerData,
      [e.target.name]: e.target.value,
      
     }));

    
  };







  return (

    <div className='formcontainer'>

    {goBack ?

            <div className='emailContainer'>
              <div className="emailBox">
                  <div className="emailContent">
                        <h2>Email Sent Succesfully</h2>
                        <a className='emailLink' style={{fontSize:"30px"}} href="/login">Login here</a>
                  </div>
              </div>
            </div>

      :

      <form onSubmit={handleSubmit} className='Authform'>
            <div className='forminputs'>
               <div className="closeicon">
                    <span onClick={()=>window.location.href='/'}><Close sx={{cursor:"pointer"}}/></span>
               </div>
                <h1>Register</h1>

            <div className="emailinp">
                <label for="name"><b>Name</b></label>
            </div>
            <input className='inputText' type="text" placeholder="Enter Name" onChange={handleChange} value={registerData.name} name="name" id="name" />
               
            <div className="emailinp">
                <label for="email"><b>Email</b></label>
            </div>
            <input className='inputText' type="email" placeholder="Enter Email" onChange={handleChange} value={registerData.email} name="email" id="email" />

            <div className="emailinp">
                <label for="psw"><b>Password</b></label>
            </div>
            <input  className='inputText' type="password" placeholder="Enter Password" onChange={handleChange} value={registerData.password} name="password" id="password" />

           <div className="emailinp">
                <label for="psw-repeat"><b>Repeat Password</b></label>
           </div>
            <input  className='inputText' type="password" placeholder="Repeat Password" onChange={handleChange} value={registerData.confirmPass}  name="confirmPass" id="confirmPass" />

            <div className="signin">
                <button type="submit" className='btn'>Register</button>
                <p>Already have an account? <a href="/login">Sign in</a>.</p>
            </div>
           
            
            </div>
           < ToastContainer/>
    
   </form>
      
    }


       
 </div>
  )
}

export default Registration
