import React, { useEffect, useState } from 'react'
import RecentCard from './RecentCard'
import recent from './recent.css'
import { getAllApartments } from '../../api-helper/apartment';
import Searchitem from '../../searchlist/searchitem/Searchitem';

const Recent = (props) => {
  console.log(props.searchLoc);

  // const [fromloadItems,setFromLoadItems]=useState(0);
  const [toloadItems,setToLoadItems]=useState(6);
  const [viewmore,setViewmore]=useState(true);

  const [allProperty,setAllProperty]=useState([{}]);

  const slice=allProperty.slice(0,toloadItems);


  const handleViewMore=()=>{
  setToLoadItems(toloadItems+6)
  }

  useEffect(()=>{
    getAllApartments().then(res=>{
      console.log("all property received");
      console.log(res.data);
      setAllProperty(res.data);
    }).catch(err=>{
      console.log("err in fetching all apartment ");
    })
  },[]);
  return (
    <section className='recent padding' id='About'>
    <div className="container">
        <div className="recenttitle">
        <h1>Recent Property Listed</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non eum nihil reprehenderit totam quibusdam sed dolorem, esse tenetur delectus quasi?</p>
        </div>
    <div className='content grid3 mtop'>
    {
        allProperty.map((val,ind)=>(
            
              ( 
                (!props.searchLoc || props.searchLoc.trim()===""?true:val.location.toLowerCase().includes(props.searchLoc.toLowerCase())) &&
                (!props.searchPrice || props.searchPrice.trim()===""?true:val.price.split(',').join('').toLowerCase().includes(props.searchPrice.toLowerCase())) &&
                (!props.searchBedroom || props.searchBedroom.trim()===""?true:(val.bedrooms ?val.bedrooms:val.No_of_bedroom) .toLowerCase().includes(props.searchBedroom.toLowerCase())) &&
                    <div className="shadow box" key={ind}>
                       {/* <RecentCard property={val} /> */}
                       <Searchitem  property={val} myproperty={false} home={true}/>
                    </div>

              )
              ))

         }
    </div>
       
        
       
    </div>
    
   {/* {viewmore && <div style={{textAlign:"center",padding:"5px",margin:"10px",background:"#e5e5e5"}}>
      <a onClick={handleViewMore} style={{cursor:"pointer",color:"green",fontSize:"18px",fontWeight:"500",padding:"5px"}}>View More...</a>
    </div>} */}
  </section>
  )
}

export default Recent
