import React from 'react'
import './searchitem.css'
import '../searchlist.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { deleteApartmentByID } from '../../api-helper/apartment';
import { useAuthContext } from '../../context/hook/useAuthContext';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';


const Searchitem = (props) => {
    const {user}=useAuthContext();
    const navigate=useNavigate();

    // console.log(props.property)

    const handleedit = (id)=>{
        navigate(`/editmyproperty/${id}`)
    }

    const handledelete = (id)=>{
       console.log("delete");
       deleteApartmentByID(JSON.parse(localStorage.getItem('user')).token,id).then(res=>{
        console.log("deleted successfully");
        console.log(res);
        window.location.reload();
       }).catch(err=>{
        console.log("err in deleting property");
        console.log(err);
       })
    }


  return (
    <>
        <div className="card">
            <div className="siImg">
                <img src={props.property.img_link} alt="" />   
            </div>
            {/* https://media.designcafe.com/wp-content/uploads/2019/12/17055334/minimalistic-living-room-interior.jpg */}
          
            <div className="siInfo" style={{padding:"5px"}}>
                 <span className='type' style={{padding:"5px",background:props.property.type_of_property==="Sale"?"#25b5791a":"#ff98001a",  //props.property.type_of_property
                         color:props.property.type_of_property==="Sale"?"#25b579":"#ff9800"}}> {props.property.type_of_property?props.property.type_of_property:"NA"}</span>
                         <span>Area - {props.property.area ? props.property.area:"Not Available" } </span>
                 <p>{props.property.bedrooms ? props.property.bedrooms : props.property.No_of_bedroom}</p>
                 {
                    props.myproperty &&
                    <div className="editdeleteicons">
                        <span onClick={()=>handleedit(props.property._id)} style={{cursor:"pointer",marginRight:"10px",color:"#ce1515"}}><EditIcon/></span>
                        <span onClick={()=>handledelete(props.property._id)}  style={{cursor:"pointer",marginLeft:"10px",color:"#ce1515"}}><DeleteForeverIcon/></span>
                    </div>
                 }
                 
                 
           </div>

            <h4 className='siInfoType' style={{marginBottom:"5px",padding:"5px",fontFamily:"CostarBrownRegular,sans-serif",fontSize:"18px",fontWeight:"600"}}>{props.property.featured_type}</h4>
            <p style={{fontSize:"16px",display:"flex",alignItems:"center"}}>  <span><LocationOnIcon style={{color:"#4c4c4c",margin:"auto"}}/></span> <span style={{color:"#4c4c4c",fontFamily:"CostarBrownLight,sans-serif"}}>{props.property.location}</span> </p>
            <div className="sibtn">
                <button className='btnn'>{props.property.price}</button>
                 <a className='a_btnn' href={props.property.web_link}>More Info</a> 
            </div>
            { props.home?
                 <div className="contactbtn">
                    <a className='contactLink' href={"/login"}>Contact</a> 
                 </div>
                :
                <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",marginBottom:"10px",padding:"0px 10px"}}>
                    {/* <p className='phoneInfo' style={{color:"#268d1f",padding:"0px 2px"}}>1234567890</p>
                    <p  className='emailInfo' style={{color:"#268d1f",padding:"0px 2px"}}>demo@gmail.com</p> */}
                    <p style={{color:"#268d1f",padding:"0px 2px"}}> <span  style={{display:"flex",justifyContent:"center",alignItems:"center"}}><PhoneAndroidIcon style={{color:"rgb(67 67 67 / 82%)",padding:"0px 3px"}}/> <span className='phoneLink'>{props.property.phone?props.property.phone:"NA"}</span> </span> </p>
                    <p style={{color:"#268d1f",padding:"0px 2px"}}> <span  style={{display:"flex",justifyContent:"center",alignItems:"center"}}><EmailIcon style={{color:"rgb(67 67 67 / 82%)",padding:"0px 5px"}}/> <span  className='emailLinkk'>{props.property.email?props.property.email:"NA"}</span> </span> </p>
                </div>
                
            }
           
        </div>


        {/* <div className="card">
            <div className="siImg">
                <img src="https://th.bing.com/th/id/OIP.qLVYj_t-HU2Yyx3v_wFgLwHaE6?pid=ImgDet&rs=1" alt="" />   
            </div>
          
            <div className="siInfo">
                <span className='type' style={{background: "#ff98001a",color:"#ff9800"}}> For Rent </span>
                <p>Willo</p>
            </div>
                <h2>Apartment</h2>
                <p>Location</p>
            <div className="sibtn">
                <button className='btnn more'>More</button>
            </div>
        </div> */}
    </>

   
  )
}

export default Searchitem
