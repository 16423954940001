import logo from './logo.svg';
import './App.css';
import Header from './header/Header.jsx';
import { Route,BrowserRouter as Router, Routes , Navigate,useNavigate} from 'react-router-dom';
import Home from './home/Home';
import Searchbar from './home/searchbar/Searchbar';
import Featured from './home/featured/Featured';
import Recent from './home/recent/Recent';
import Award from './home/awards/Award';
import Explore from './home/explore/Explore';
import Footer from './home/footer/Footer';
import Searchlist from './searchlist/Searchlist';
import { HashLink  } from 'react-router-hash-link';
import Registration from './Auth/registration/Registration';
import Login from './Auth/login/Login';
import AddProperty from './home/addProperty/AddProperty';
import { useAuthContext } from './context/hook/useAuthContext';
import Errorpage from './error/Errorpage';
import EmailVerified from './Auth/emailVerification/EmailVerified';
import Notverified from './Auth/emailVerification/Notverified';
import ForgetPass from './Auth/forgetpass/ForgetPass';
import SetNewPassword from './Auth/setNewPass/SetNewPassword';
import { useState } from 'react';
import TokenExpired from './Auth/forgetpass/TokenExpired';
import MyProperty from './home/myProperty/MyProperty';
import EditProperty from './home/addProperty/EditProperty';
import { NavLink } from 'react-router-dom';
import Resetpassword from './Auth/setNewPass/Resetpassword';



function App() {
   const {user}=useAuthContext();
 
   const [verificationpage,setVerificationpage]=useState(false);
   const [newpasspage,setNewpasspage]=useState(false);

  return (

      
   
     <Router>
      <Routes>
         <Route path="/" element={ user ? <Navigate to={"/searchlist"}/>:<Home/>}/> 
         <Route path="/login" element={user ? <Navigate to={"/searchlist"}/> : <Login/> }/>
         <Route path="/register" element={user ? <Navigate to={"/searchlist"}/> :<Registration setVerificationpage={setVerificationpage}/>}/>

         <Route path="/searchlist" element={<Searchlist/>}/>  
         {/* <Route path="/searchlist" element={user ? <Searchlist/> : <Navigate to={"/"}/>}/>   */}
         <Route path="/addproperty" element={localStorage.getItem('user') ? <AddProperty/>:<Navigate to={"/login"}/>}/> 
         <Route path="/myproperties" element={ localStorage.getItem('user') ? <MyProperty/>:<Navigate to={"/login"}/>}/> 
         <Route path="/editmyproperty/:id" element={localStorage.getItem('user') ? <EditProperty/> : <Navigate to={"/login"}/> }/>
         

         <Route path="/forgotPassword"   element={<ForgetPass  setNewpasspage={setNewpasspage}/>}/>  

         <Route path="/resetpassword/:id"  element={<Resetpassword/> }/> 
         <Route path="/setNewPassword/:id" element={<SetNewPassword/>}/> 
         <Route path="/tokenexpired" element={<TokenExpired/> }/> 

         {verificationpage && <Route path="/emailverified"  element={<EmailVerified setVerificationpage={setVerificationpage}/>}/>   }
         {verificationpage &&  <Route path="/Notverified"  element={<Notverified setVerificationpage={setVerificationpage}/>}/> }
           
         
      </Routes>
     </Router>
   
  );
}

export default App;
